import { WixOOISDKAdapter } from '@wix/bookings-adapter-ooi-wix-sdk/dist/src/WixOOISDKAdapter';
import { FailReasons } from '../constants';
import { WidgetConfig } from '../../../types/shared-types';
import { biDefaults } from '../bi/consts';
import {
  isServiceConnectedToPricingPlan,
  isServiceOfferedAsPricingPlan,
} from '@wix/bookings-uou-mappers';
import { ServiceType } from '@wix/bookings-uou-types';
import { getTrackingInfoForBookButtonClick } from '@wix/bookings-analytics-adapter';
import { isEcomSite } from '../../../api/siteIsEcom';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { navigateToBookingsFormPage } from '@wix/bookings-booking-navigation';
import { navigateToBookingsCalendarPage } from '@wix/bookings-catalog-calendar-viewer-navigation';

export const handleNavigation = async ({
  config,
  isPreview,
  wixSdkAdapter,
  onNavigationFailed,
  locationId,
  timezone,
  flowAPI,
  isFormOOINavigationEnabled,
  isAnywherePublicDataOverridesEnabled = false,
  isRouterPrefixNavigationEnable = false,
  isBookingsV1ShutdownEnabled,
}: {
  config: WidgetConfig;
  wixSdkAdapter: WixOOISDKAdapter;
  isPreview: boolean;
  onNavigationFailed: ({ failReasons }: { failReasons: FailReasons[] }) => void;
  locationId?: string;
  timezone?: string;
  flowAPI: ControllerFlowAPI;
  isFormOOINavigationEnabled: boolean;
  isAnywherePublicDataOverridesEnabled?: boolean;
  isRouterPrefixNavigationEnable?: boolean;
  isBookingsV1ShutdownEnabled: boolean;
}) => {
  const featureEnabled = config.isFeatureEnabled;
  const siteIsEcom =
    !isBookingsV1ShutdownEnabled || (await isEcomSite(flowAPI));
  if (
    isPreview ||
    wixSdkAdapter.isDemoMode() ||
    wixSdkAdapter.isTemplateMode() ||
    (featureEnabled &&
      siteIsEcom &&
      (await isPricingPlanNavigateEnabled(wixSdkAdapter, config.serviceDto)))
  ) {
    const referral = biDefaults.service_page_referral_info;
    const serviceSlug = config.serviceDto!.urlName;
    const isCourse = config.serviceDto!.type === ServiceType.COURSE;

    if (config.serviceDto) {
      const trackingInfo = getTrackingInfoForBookButtonClick({
        service: config.serviceDto,
        businessName: config.businessInfo.name || '',
      });
      wixSdkAdapter.trackAnalytics(trackingInfo);
    }

    if (isFormOOINavigationEnabled) {
      const serviceId = config.serviceDto!.id;
      if (isCourse) {
        if (
          flowAPI.experiments.enabled(
            'specs.bookings.navigationFromNewModule',
          )
        ) {
          navigateToBookingsFormPage({
            platformAPIs: flowAPI.controllerConfig.platformAPIs,
            wixCodeApi: flowAPI.controllerConfig.wixCodeApi,
            serviceId,
            serviceSlug,
            isAnywhereSpecEnable: isAnywherePublicDataOverridesEnabled,
            queryParams: { referral, timezone },
          });
        } else {
          wixSdkAdapter.navigateToBookingsFormPage({
            serviceId,
            serviceSlug,
            isAnywhereSpecEnable: isAnywherePublicDataOverridesEnabled,
            queryParams: { referral, timezone },
          });
        }
      } else {
        if (
          flowAPI.experiments.enabled(
            'specs.bookings.navigateToCalendarPageWithNavigationModule',
          )
        ) {
          navigateToBookingsCalendarPage(flowAPI.controllerConfig.wixCodeApi, {
            serviceSlugOrBasket: serviceSlug,
            navigationContext: {
              referral,
              location: locationId,
              timezone,
            },
          });
        } else {
          wixSdkAdapter.navigateToBookingsCalendarPage(
            serviceSlug,
            {
              referral,
              location: locationId,
              timezone,
            },
            isRouterPrefixNavigationEnable,
          );
        }
      }
    } else {
      const optionalParams = {
        referral,
        location: locationId,
        timezone,
      };
      return wixSdkAdapter.navigateToBookingsBookAction(
        !isCourse,
        serviceSlug,
        optionalParams,
        isRouterPrefixNavigationEnable,
      );
    }
  } else {
    if (!featureEnabled) {
      onNavigationFailed({ failReasons: [FailReasons.Premium] });
    } else if (!siteIsEcom) {
      onNavigationFailed({ failReasons: [FailReasons.NonEcomSite] });
    } else {
      const failReasons: FailReasons[] = [];
      if (!(await wixSdkAdapter.isPricingPlanInstalled())) {
        failReasons.push(FailReasons.PricingPlanNotInstalled);
      }
      if (!isServiceConnectedToPricingPlan(config.serviceDto)) {
        failReasons.push(FailReasons.NoPlansAssignedToService);
      }
      onNavigationFailed({ failReasons });
    }
  }
};

const isPricingPlanNavigateEnabled = async (wixSdkAdapter, serviceDto) => {
  const isPricingPlanInstalled = await wixSdkAdapter.isPricingPlanInstalled();
  return (
    !isServiceOfferedAsPricingPlan(serviceDto, isPricingPlanInstalled) ||
    (isServiceConnectedToPricingPlan(serviceDto) && isPricingPlanInstalled)
  );
};
